.form-connexion {
  display: flex;
  flex-direction: column;

  .connexion-title {
    text-align: center;
  }

  .connexion-input {
    width: 90%;
    margin: 20px auto auto;
  }

  .connexion-button {
    width: 90%;
    margin: 20px auto auto;
  }
}