.menu {
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);

  background: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Alex Brush', serif !important;
}

.menu-title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #2C5948;
}

.menu-icon {
  height: auto;
  width: 50px;
  margin-right: 10px;
}

.menu-burger div {
  width: 35px;
  height: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 13px;
  background-color: #2C5948;
}

.menu-burger div:nth-child(2) {
  margin-left: 15px;
}

.navigation-menu {
  overflow: hidden;
}

.navigation {
  font-family: 'Alex Brush', serif !important;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: -webkit-fill-available !important;
  height: 100%;

  left: -100%;
  transition: all 0.5s ease-in-out;

  background: #95ACA3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  h1 {
    font-weight: 100;
    font-size: 40px;
    margin: 0;
  }
}

.navigation-show {
  left: 0;
}

.navigation-hidden {
  left: -100%;
}

.bottom-navigation {
  position: absolute;
  bottom: 0;
  transform: rotate(-45deg);
  width: 100%;
  height: 10%;
  background: rgba(44, 89, 72, 0.5);
}

.footer {
  position: relative;
  bottom: 0;
}

.footer-text {
  font-size: 16px;
}