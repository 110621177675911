

.contact-form {

  .form-input {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-button {
    margin-top: 20px;
    width: 140px;
  }

  padding-bottom: 50px;
}