.intro-section {

}

.intro-image-container {
  display: flex;
}

.intro-image {
  width: 100%;
}

.cards-section {
  background: black;
  padding-bottom: 30px;
}

.cards-intro {
  margin-top: 0;
  padding-top: 25px;
}

.cards-image {
  width: 60%;
  height: auto;
  margin-bottom: 20px;
  margin-left: -20px;
}

.cards-button {
  display: flex;
  justify-content: center;
}

.map {
  width: 100%;
  height: auto;
}

.more-information {
  margin: auto;
  width: 80%;
}