
.input {

  label {
    font-family: unset !important;
    font-weight: 800;
    font-size: 22px;
    z-index: -1;
    color: rgba(0, 0, 0, 0.6) !important;
  }

  div {
    font-family: unset !important;
    font-size: 22px;
    border-bottom: none !important;
    background-color: transparent !important;
  }

  div:after {
    border-bottom: 2px solid #2C5948 !important;
  }

  .input-material {
    width: 100% !important;
    height: available;
  }

  margin: 10px auto;
  background-color: rgba(239, 234, 203, 0.2) !important;
  border-bottom: 1px solid #2C5948;
  border-right: 1px solid #2C5948;
}

.input:focus {

}

.input:hover {

}