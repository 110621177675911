
.button {
  border-radius: 16px !important;
  text-transform: unset !important;
  font-size: 18px !important;
  font-family: unset !important;
}

.button-gold {
  border-color: #D9CD86 !important;
  color: #D9CD86 !important;
  box-shadow: 0 4px 4px #D9CD86;
}

.button-gold:hover {
  border-color: #D9CD86;
  color: #D9CD86;
}

.button-gold:focus {
  border-color: #D9CD86;
  color: #D9CD86;
}

.button-fir {
  border-color: #2C5948 !important;
  color: #2C5948 !important;
}

.button-fir:hover {
  border-color: #2C5948;
  color: #2C5948;
}

.button-fir:focus {
  border-color: #2C5948;
  color: #2C5948;
}