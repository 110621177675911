.subtitle {
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  width: auto;
  font-family: 'Alex Brush', serif !important;
}

.subtitle-underline-fir {
  color: #487A6A;
  //border-bottom: solid 1px #487A6A;
}

.subtitle-underline-gold {
  color: #D9CD86;
  text-underline: #D9CD86;
}