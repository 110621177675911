.card {
  margin: 80px 10px 40px 10px;

  .card-border {
    display: flex;
    align-items: center;
  }

  .card-content {
    display: flex;
    justify-content: space-around;
  }

  .card-border-v {
    border-top: solid 1px #D9CD86;
    border-bottom: solid 1px #D9CD86;
    height: 10px;
    width: 50%;
    text-align: center;
    margin: auto;
  }

  .card-border-h {
    border-left: solid 1px #D9CD86;
    border-right: solid 1px #D9CD86;
    height: 95%;
    width: 10px;
    text-align: center;
    margin: auto;
  }

  .card-content-text {

  }

  .leaf-image {
    width: 20%;
    max-width: 800px;
    z-index: -1;
  }

  .leaf-image-top {
    transform: rotate(270deg);
  }

  .leaf-image-bottom {
    transform: rotate(90deg);
  }

  .anti-leaf {
    width: 20%;
    max-width: 800px;
  }
}

.progress-bar {
  width: 80%;
  margin: auto;

  span > span {
    border-color: #2C5948 !important; //try to fix later
  }
}