
.menu-input {
  width: auto;

  border-right: none;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.input-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-save-menu {
  width: 80%;
  margin: 60px auto 0;
}

.save-button {
  width: 100%;
}