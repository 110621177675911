.banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;

  .squares-banner {
    display: flex;
    flex-direction: column;
  }

  .squares-revers {
    display: flex;
    flex-direction: column-reverse;
  }

  .square {
    width: 15px;
    height: 15px;
    margin: 1px;
    opacity: 0.75;
  }

  .full-square {
    background: #DDD49F;
  }

  .empty-square {
    border: 1px solid #DDD49F;
    width: 13px;
    height: 13px;
  }
}