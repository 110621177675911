
.map-container {
  z-index: -1;
  overflow: hidden;
  margin: auto;
  width: 80%;
}

.contact-information {
  margin: auto;
  width: 90%;
}